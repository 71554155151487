import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyDH1UjNk0xHVtHmxzCYi0eos6k8WJpVHUU",
    authDomain: "talkative-app-7dd00.firebaseapp.com",
    projectId: "talkative-app-7dd00",
    storageBucket: "talkative-app-7dd00.appspot.com",
    messagingSenderId: "675443923893",
    appId: "1:675443923893:web:c627be8874064e6b1417fc",
    measurementId: "G-5TL783DDNL"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig); 
const db = firebaseApp.firestore(); 
const auth = firebase.auth(); 
const provider = new firebase.auth.GoogleAuthProvider(); 

export {auth, provider};
export default db;