import React, { useEffect, useState } from 'react'
import './Chat.css'; 
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MicIcon from '@mui/icons-material/Mic';
import { useParams } from 'react-router';
import db from '../../firebase.js';
import firebase from '@firebase/app-compat';
import {useStateValue} from '../../StateProvider.js'; 
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

function Chat() {
  const [message, setMessage] = useState(''); 
  const {chatId} = useParams();
  const [{user}, dispatch] = useStateValue(); 
  const [chatIds, setChatIds] = useState([]); 
  const [showChatCreator, setShowChatCreator] = useState(false);
  
  const [showChatCreationTime, setShowChatCreationTime] = useState(false);
  const [chatCreationTimeDisplay, setChatCreationTimeDisplay] = useState(''); 

  const [showFirstMessenger, setShowFirstMessenger] = useState(false);
  const [firstMessengerDisplay, setFirstMessengerDisplay] = useState('');

  const [showLastMessenger, setShowLastMessenger] = useState(false);
  const [lastMessengerDisplay, setLastMessengerDisplay] = useState(''); 

  const toggleShowChatCreator = () => {
    setShowChatCreator(!showChatCreator); 
  }

  const toggleShowChatCreationTime = () => {
    setShowChatCreationTime(!showChatCreationTime); 
  }

  const toggleShowFirstMessenger = () => {
    setShowFirstMessenger(!showFirstMessenger); 
  }

  const toggleShowLastMessenger = () => {
    setShowLastMessenger(!showLastMessenger); 
  }


  const [randomAvatarNum, setRandomAvatarNum] = useState();
  useEffect(() => {
    setRandomAvatarNum(Math.random() * 100000);
  }, [chatId]);

  useEffect(() => {
    db.collection('chats').onSnapshot(snapshot => {
      setChatIds(snapshot.docs.map(doc => doc.id));
    }) 
  }, [])

  const [chatName, setChatName] = useState('');
  const [chatCreator, setChatCreator] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if(chatId) {
      //Set Chat Name
      db.collection('chats').doc(chatId).onSnapshot(snapshot => {
        setChatName(snapshot.data().name);
        setChatCreator(snapshot.data().creator);
        setChatCreationTimeDisplay(new Date(snapshot.data().creationTime?.toDate()).toDateString());
      });
      
      //Set Chat Messages
      db.collection('chats').doc(chatId).collection('messages').orderBy('timestamp', 'asc')
      .onSnapshot(snapshot => {
        setMessages(snapshot.docs.map(doc => doc.data()))
      })
      
      db.collection('chats').doc(chatId).collection('messages').orderBy('timestamp', 'asc')
      .onSnapshot(snapshot => {
        let messagesDocs= snapshot.docs.map(doc => doc.data());
        if(messagesDocs.length > 0) {
          setFirstMessengerDisplay(messagesDocs[0].name);
          setLastMessengerDisplay(messagesDocs[messagesDocs.length - 1].name);
        } else {
          setFirstMessengerDisplay('Invalid');
          setLastMessengerDisplay('Invalid'); 
        }
      })
      setShowChatCreator(false);
      setShowChatCreationTime(false);
      setShowFirstMessenger(false);
      setShowLastMessenger(false); 
    }
  }, [chatId])


  const sendMessage = (e) => {
    e.preventDefault(); 

    db.collection('chats').doc(chatId).collection('messages').add({
      message: message,
      name: user.displayName,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(), 
    });
    setMessage('');
  }; 

  function isChatCreator() {
    return user.displayName == chatCreator
  }

  const deleteChat = () => {
    if(isChatCreator()) {
      if(window.confirm('Are you sure you want to DELETE this chat?') == true) {
        db.collection('chats').doc(chatId).delete(); 
      } 
    }   
}




  return chatIds.includes(chatId) ? ( 
    <div className='chat'>
      <div className='chat-header'>
        <Avatar src={`https://avatars.dicebear.com/api/human/${randomAvatarNum}.svg`}/> 
        <div className='chat-header-info'>
          <h1>{chatName}</h1>
          <p>Last seen at {new Date(messages[messages.length - 1]?.timestamp?.toDate()).toUTCString()}</p>
        </div>
        <div className='chat-info-header-right'>
          {/* <IconButton><span title='Search'><SearchIcon /></span></IconButton> */}
          {isChatCreator() ? (
            <IconButton className='delete-chat' onClick={deleteChat}><span title='Delete'><DeleteIcon /></span></IconButton>
          ): (
            null
          )}
          <div className='dropdown'>
            <IconButton><span title='More Info'><InfoIcon /></span></IconButton>
              <div className='dropdown-items'>
                {showChatCreator ? <h3 onClick={toggleShowChatCreator}>{chatCreator}</h3> : <h3 onClick={toggleShowChatCreator}>Chat Creator</h3>}
                {showChatCreationTime ? <h3 onClick={toggleShowChatCreationTime}>{chatCreationTimeDisplay}</h3> : <h3 onClick={toggleShowChatCreationTime}>Time of Chat Creation</h3>}
                {showFirstMessenger ? <h3 onClick={toggleShowFirstMessenger}>{firstMessengerDisplay}</h3> : <h3 onClick={toggleShowFirstMessenger}>First Messenger</h3>}
                {showLastMessenger ? <h3 onClick={toggleShowLastMessenger}>{lastMessengerDisplay}</h3> : <h3 onClick={toggleShowLastMessenger}>Last Messenger</h3> }
              </div>
          </div>
        </div>
      </div>
      <div className='chat-body'>
        {messages.map(message => (
          <p className={`chat-message ${message.name == user.displayName && 'chat-reciever'}`}>
          <span className='chat-name'>{message.name}</span>
          {message.message}
          <span className='chat-timestamp'>{new Date(message.timestamp?.toDate()).toUTCString()}</span>
         </p>
        ))}
      </div>
      <div className='chat-footer'>
        <InsertEmoticonIcon />
        <form>
          <input type='text' placeholder='Type a message' value={message} onChange={e => setMessage(e.target.value)}/>
          <button onClick={sendMessage}>Send Message</button>
        </form>
        <MicIcon /> 
      </div>
    </div>
  ) : <h1>Open a chat!</h1>
}

export default Chat; 