import './SidebarChat.css';
import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import db from '../../firebase';
import {Link} from 'react-router-dom';
import {useStateValue} from '../../StateProvider.js'; 


function SidebarChat({id, chatName, addNewChat}) {
  const [{user}, dispatch] = useStateValue(); 
  const [num, setNum] = useState();
  useEffect(() => {
    setNum(Math.random() * 100000)
  }, [])

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if(id) {
      db.collection('chats').doc(id).collection('messages').orderBy('timestamp', 'desc')
      .onSnapshot(snapshot => setMessages(snapshot.docs.map(doc => doc.data())))
    }
  }, [id])
  

  const createChat = () => {
    const chatName = prompt('Please enter a chat name'); 
    if(chatName) {
      db.collection('chats').add({
        name: chatName,
        creator: user.displayName, 
      }) 
    }
  };

  return addNewChat ? (
    <div className='sidebarChat' onClick={createChat}>
      <h2>Add New Chat</h2>
    </div>
  ) : (
    <Link to={`/chats/${id}`} >
      <div className='sidebarChat'>
        <Avatar src={`https://avatars.dicebear.com/api/human/${num}.svg`} />
        <div className='sidebarChat-info'>
          <h2>{chatName}</h2>
          <p>{messages[0]?.message.length > 47 ? messages[0]?.message.substring(0, 47) + "..." : messages[0]?.message}</p>
        </div>
      </div>
    </Link>
  )
}

export default SidebarChat;