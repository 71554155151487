import './App.css';
import Sidebar from './components/Sidebar/Sidebar.js';
import Chat from './components/Chat/Chat.js';
import Login from './components/Login/Login.js';
import {Routes, Route} from 'react-router-dom'; 
import { useStateValue } from './StateProvider';

function App() {
  const [{user}, dispatch] = useStateValue(); 

  return (
    <div className="app">
      {console.log("Current User: " + user)}
      { user ? (
        <div className='app-body'>
          <Sidebar />
          <Routes>
            <Route path='/' element={<Chat /> } /> 
            <Route path='/chats/:chatId' element={<Chat />} />
          </Routes>
        </div>
      ) : (
        <Login />
      )
      }
    </div>
  );
}

export default App;
