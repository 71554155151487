import React, { useEffect, useState } from 'react'
import './Sidebar.css'; 
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SidebarChat from '../SidebarChat/SidebarChat.js'; 
import Avatar from '@mui/material/Avatar';
import db from '../../firebase.js';
import {useStateValue} from '../../StateProvider.js'; 
import CloseIcon from '@mui/icons-material/Close';
import {auth} from '../../firebase.js';
import { actionTypes } from '../../reducer';
import firebase from '@firebase/app-compat';

function Sidebar() {
  const [chats, setChats] = useState([]); 
  const [chatNames, setChatNames] = useState([]);
  const [{user}, dispatch] = useStateValue();
  const [search, setSearch] = useState('');  
  
  const searchChat = (e) => {
    e.preventDefault();
    setSearch(''); 
  };

  const clearSearch = () => {
    setSearch(''); 
  };


  useEffect(() => {
    db.collection('chats').onSnapshot((snapshot) => {
      setChats(snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      })))

      setChatNames(snapshot.docs.map(doc => doc.data().name));
    })

  }, []);

  const createChat = () => {
    var chatName = prompt('Create New Chat', "Chat Name");
    if(chatName) {
      while(chatNames.includes(chatName.trim()) || chatName.length > 35) {
        if(chatNames.includes(chatName.trim())) {
        chatName = prompt('NAME TAKEN! Create New Chat', 'Chat Name'); 
        } else {
          chatName = prompt('NAME TOO LONG! Shorten Name of New Chat', 'Chat Name'); 
        }
      }
      db.collection('chats').add({
        name: chatName,
        creator: user.displayName,
        creationTime: firebase.firestore.FieldValue.serverTimestamp(),
      }); 
  }
  };

  function checkIfChatCreator(chat) {
    return chat.data.creator == user.displayName; 
  }

  const displayCreatorChats = () => setChats(chats.filter(checkIfChatCreator)); 

  const displayAllChats = () => {
    db.collection('chats').onSnapshot((snapshot) => {
      setChats(snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      })))
  }); 
}

const signOut = () => {
  auth.signOut();
  dispatch({
    type: actionTypes.REMOVE_USER,
    user: null, 
  })
} 

const isPotentialChatName = (chatName) => {
  return search.toLowerCase().trim() == chatName.toLowerCase().trim().substring(0, search.trim().length); 
}

const isPotentialSearch = (chatObj) => {
  return isPotentialChatName(chatObj.data.name); 
}
         

  return (
    <div className='sidebar'>
      <div className='sidebar-header'>
        <Avatar src={user?.photoURL}/>
        <div className='sidebar-header-right'>
          <IconButton className='add-new-chat'>
            <span title='Add New Chat'>
            <AddCommentIcon title='Add New Chat' onClick={createChat }/>
            </span>
          </IconButton>
          <div className='dropdown'>
            <IconButton><MoreVertIcon className='dropdown-button'/></IconButton>
              <div className='dropdown-items'>
                <h3 onClick={displayCreatorChats}>Your Chats</h3>
                <h3 onClick={displayAllChats}>All Chats</h3>
                <h3 onClick={signOut}>Sign Out</h3>
              </div>
          </div>
        </div>
      </div>
      <div className='sidebar-search'>
        <div className='sidebar-search-container'>
          <SearchIcon />
          <form>
            <input placeholder='Search new chat' type='text' value={search} onChange={e => setSearch(e.target.value)}/> 
            <button onClick={searchChat}>Search Chat</button>
          </form>
          <IconButton onClick={clearSearch}><CloseIcon /></IconButton>
        </div>
      </div>
      <div className='sidebar-chats'>
        {search.trim() ? (
          chats.filter(isPotentialSearch).map(chat => (
            <SidebarChat key={chat.id} id={chat.id} chatName={chat.data.name} />
          ))
        ) : (
          // <SidebarChat addNewChat/> 
          chats.map((chat) => ( 
          <SidebarChat key={chat.id} id={chat.id} chatName={chat.data.name} />
      ))
        )}
      </div>
    </div>
  )
}

export default Sidebar; 