import './Login.css'; 
import React from 'react';
import Button from '@mui/material/Button';
import {auth, provider} from '../../firebase.js';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import LogoT from '../../talkative-logo/default.png'; 

function Login() {
  const [{}, dispatch] = useStateValue()

  const signIn = () => {
    auth.signInWithPopup(provider).then(result => {
      dispatch({
        type: actionTypes.SET_USER,
        user: result.user,
      });
    }).catch(error => alert(error.message));
  }; 

  return (
    <div className='login'>
        <div className='login-container'>
            <img src={LogoT} alt='talkative-logo' />
            <div className='login-text'>
                <h1>Sign in to Talkative</h1>
            </div>
            <Button onClick={signIn}>Sign in with Google</Button>
        </div>
    </div>
  )
}

export default Login;